import React from "react"
import "./Section.scss"

export default function Section(props) {
    const { className, title, children, size, alignment, hideUnderline } = props
    return (
        <div
            className={`section ${
                alignment === "center" ? "section--centered" : ""
            } ${className || ""}`}
        >
            <div
                className={`section__header text-title ${
                    alignment === "center" ? "section__header--centered" : ""
                } ${size ? `section__header--${size}` : ""} ${
                    hideUnderline ? `section__header--no-border` : ""
                }`}
            >
                {title}
            </div>
            <div
                className={`section__content text-content ${
                    size ? `section__content--${size}` : ""
                }`}
            >
                {children}
            </div>
        </div>
    )
}
